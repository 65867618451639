import React from "react";
import { Carousel, CarouselItem  } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
// import carousel0video from "../assets/img/carousel/carousel0.mp4";
import { Player, ControlBar } from 'video-react';

class CarouselComp extends React.Component{
// const CarouselComp = () =>  {
    render() {
    
    return (
        
        <Carousel className="crsl" autoPlay interval="6000" transitionTime="6000" indicators={false} infiniteLoop id="home">
            <CarouselItem>
                <div className="carousel0 w-100">
                    <Container>
                    </Container>       
                </div>    
            </CarouselItem>    


                        {/* <Row>
                            <Col> */}
                                {/* <video 
                                    muted
                                    autoPlay={"autoplay"}
                                    preLoad="auto"
                                    loop 
                                    height="100%"
                                    width="100%"
                                controls>
                                    <source src={carousel0video} 
                                    type="video/mp4"></source>
                                </video> */}
                            {/* </Col>
                        </Row> */}        

            
            <CarouselItem>
                <div className="carousel1 w-100"> 
                    <Container>
                        <Row>
                            <Col>
                            {/* <h1 className="text-white-50 text-left animate__animated animate__fadeInUp">PT.  Endo Medica Nusantara (EMN)</h1>
                            <h3 className="text-white-50 text-left text-black-50 animate__animated animate__fadeInUp animate__delay-1s"> Importir alat kesehatan yang terpercaya
                            </h3> */}
                            </Col>
                        </Row>

                    </Container>       
                </div>    
                 
            </CarouselItem>
            <CarouselItem>
                {/* <div className="carousel3 min-vh-100 w-100">  */}
                <div className="carousel2 w-100"> 
                    <Container>
                        <Row>
                            <Col>
                            {/* <h1 className="text-white-50 text-left animate__animated animate__fadeInUp">PT.  Endo Medica Nusantara (EMN)</h1> */}
                            {/* <h3 className="text-white-50 text-left text-black-50 animate__animated animate__fadeInUp animate__delay-1s"> Sumber Daya Manusia yang handal, ahli dan berpengalaman
                            </h3> */}
                            </Col>
                        </Row>

                    </Container>       
                </div>    
                 
            </CarouselItem>                  
            <CarouselItem>
                {/* <div className="carousel2 min-vh-100 w-100">  */}
                <div className="carousel3 w-100"> 
                    <Container>
                        <Row>
                            <Col>
                            {/* <h1 className="text-white-50 text-left animate__animated animate__fadeInUp">PT.  Endo Medica Nusantara (EMN)</h1> */}
                            {/* <h3 className="text-white-50 text-left text-black-50 animate__animated animate__fadeInUp animate__delay-1s"> Produk yang berkualitas, bersertifikasi resmi dan diakui tingkat dunia.
                            </h3> */}
                            </Col>
                        </Row>

                    </Container>       
                </div>    
                 
            </CarouselItem>
            <CarouselItem>
                {/* <div className="carousel2 min-vh-100 w-100">  */}
                <div className="carousel4"> 
                    <Container>
                        <Row>
                            <Col>
                            {/* <h1 className="text-white-50 text-left animate__animated animate__fadeInUp">PT.  Endo Medica Nusantara (EMN)</h1> */}
                            {/* <h3 className="text-white-50 text-left text-black-50 animate__animated animate__fadeInUp animate__delay-1s"> Produk yang berkualitas, bersertifikasi resmi dan diakui tingkat dunia.
                            </h3> */}
                            </Col>
                        </Row>

                    </Container>       
                </div>    
                 
            </CarouselItem>      

            <CarouselItem>
                {/* <div className="carousel2 min-vh-100 w-100">  */}
                <div className="carousel5 w-100"> 
                    <Container>
                        <Row>
                            <Col>
                            {/* <h1 className="text-white-50 text-left animate__animated animate__fadeInUp">PT.  Endo Medica Nusantara (EMN)</h1> */}
                            {/* <h3 className="text-white-50 text-left text-black-50 animate__animated animate__fadeInUp animate__delay-1s"> Produk yang berkualitas, bersertifikasi resmi dan diakui tingkat dunia.
                            </h3> */}
                            </Col>
                        </Row>

                    </Container>       
                </div>    
                 
            </CarouselItem>                     
        </Carousel>
          
    )
                    };
}

export default CarouselComp;